<template>
  <div class="">
    <div class="form-row">
      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-6"
        label="รหัส"
        v-model="formData.code"
        :validations="[
          {text: 'required!', value: v.formData.code.$dirty && !v.formData.code.required}
        ]">
      </sgv-input-text>

      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-6"
        label="ชื่อ"
        v-model="formData.name"
        :validations="[
          {text: 'required!', value: v.formData.name.$dirty && !v.formData.name.required}
        ]">
      </sgv-input-text>

      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-12"
        label="Token"
        v-model="formData.token"
        :validations="[
          {text: 'required!', value: v.formData.token.$dirty && !v.formData.token.required},
        ]">
      </sgv-input-text>

      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-12"
        label="chatId"
        v-model="formData.chatId">
      </sgv-input-text>
    </div>

    <div class="form-row">
      <div class="form-group col-12">
        <sgv-input-check
          :disabled="$auth.disabled(method)"
          label="ใช้งาน"
          inline
          v-model="formData.isActive">
        </sgv-input-check>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    templateType: {
      type: String,
      required: true
    },
    deviceType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    },
    v: {
      type: Object,
      required: true
    },
  }
}
</script>

<style lang="css">
</style>
